<template>
    <SignIn></SignIn>
    <Footer v-if="!isMobile"></Footer>
</template>

<script>
  import SignIn from '@/components/user/SignIn.vue'
  import Footer from '@/components/footer/Footer.vue'

import {mapGetters,mapActions} from 'vuex'
  export default {
    components: {
        SignIn,
        Footer
    },
      computed: {
        ...mapGetters([
            'isMobile'
        ]),
    },
  }
</script>

